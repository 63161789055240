import React from "react"
import styled from "styled-components"
import Forme2 from "../images/Forme2.svg"
import HomePicture from "../images/38507-removebg.png"

const Home = () => {
  return (
    <StartWrapper>
      <img className="Forme2" src={Forme2} alt="" />
      <img className="Forme1" src={Forme2} alt="" />
      <div className="title">
        <h1>Services informatiques aux particuliers et entreprises</h1>
        <h2>
          Intervention en entreprise et à domicile 7j/7 de 9h à 19h, à Toulon et
          ses environs.
        </h2>
      </div>
      <img className="HomePicture" src={HomePicture} alt="" />
    </StartWrapper>
  )
}

const StartWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 90vh;
  background: #577ed9;
  color: #e2e8f0;
  padding: 1rem 0 1rem 0;
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr 400px;
  z-index: -1;

  .title {
    position: absolute;
    top: 10%;
    left: 10%;
    width: 30%;
    height: 90vh;
    padding: 2%;

    h1 {
      font-size: 3rem;
    }
    h2 {
      font-size: 1.5rem;
    }
  }
  .Forme1 {
    position: absolute;
    top: -22%;
    left: 0;
    width: 25%;
    height: auto;
    overflow: hidden;
  }
  .Forme2 {
    position: absolute;
    top: 55%;
    left: 40%;
    width: 25%;
    height: auto;
    overflow: hidden;
  }
  .HomePicture {
    position: absolute;
    top: 0;
    left: 60%;
    border-radius: 20% 0 0 20%;
    background: #577ed9;
    opacity: 0.7;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  }
  @media screen and (max-width: 1332px) {
    .title {
      h1 {
        font-size: 2rem;
      }
      h2 {
        font-size: 1rem;
      }
    }
  }
  @media screen and (max-width: 899px) {
    .title {
      h1 {
        font-size: 1.5rem;
      }
      h2 {
        font-size: 1rem;
      }
    }
  }
  @media screen and (max-width: 676px) {
    .title {
      h1 {
        font-size: 1rem;
      }
    }
  }

  @media screen and (max-width: 425px) {
    grid-template-columns: 1fr;
    .title {
      width: 90%;
      height: 90vh;
      padding: 2%;
      h1 {
        font-size: 2rem;
      }
      h2 {
        font-size: 1rem;
      }
    }
    .HomePicture {
      display: none;
    }
  }
`

export default Home
