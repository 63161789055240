import React, { createContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

export const EtapesContext = createContext()

export const EtapesProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsEtape(sort: { fields: id }) {
        edges {
          node {
            title
            id
            etape
            description
          }
        }
      }
    }
  `)

  return (
    <EtapesContext.Provider value={[data]}>{children}</EtapesContext.Provider>
  )
}
