import React, { useContext, useState } from "react"
import { EtapesContext } from "../context/EtapesContext"
import styled from "styled-components"
import Wave from "../images/layered-waves.svg"
const Etapes = () => {
  const [data] = useContext(EtapesContext)
  const [etapes] = useState(data.allDatoCmsEtape.edges)
  return (
    <>
      <img
        src={Wave}
        style={{ margin: "-50% 0 -2% 0", width: "100%", height: "100%" }}
        alt=""
      />
      <EtapesWrapper>
        <h5>En étapes</h5>
        <h2>Prendre Rendez-vous</h2>
        <div className="Etapes">
          {etapes.map(({ node: etape }) => {
            return (
              <div className="box" key={etape.id}>
                <h5>{etape.etape}</h5>
                <h4>{etape.title}</h4>
                <p>{etape.description}</p>
              </div>
            )
          })}
        </div>
      </EtapesWrapper>
    </>
  )
}
const EtapesWrapper = styled.div`
  width: 100%;
  background: #577ed9;
  padding: 3% 0 10% 0;
  position: relative;
  h2 {
    text-align: center;
    margin: 3% 0 0 0;
  }

  h5 {
    text-align: center;
    margin: 2% 0 0 0;
    color: #57d971;
  }

  .Etapes {
    background: #577ed9;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 10px;
    width: 100%;
    margin: 10% 0 0 0;

    .box {
      background: #f7f9f9;
      width: 16rem;
      padding: 2%;
      margin: 1%;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
        rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
        rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
      border-radius: 0 30% 0 30%;
      text-align: center;

      h5 {
        padding: 2%;
        margin: 0 0 10% 0;
        background: #7157d9;
        opacity: 0.8;
        border-radius: 30px 30px 30px 30px;
        width: 4rem;
        height: 2rem;
        color: #fff;
        text-align: center;
      }
    }
  }
`

export default Etapes
