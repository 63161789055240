import React, { useContext, useState } from "react"
import { ServicesContext } from "../context/ServicesContext"
import styled from "styled-components"

const Services = () => {
  const [data] = useContext(ServicesContext)
  const [services] = useState(data.allDatoCmsService.edges)
  return (
    <ServicesWrapper>
      <h5>En bref</h5>
      <h2>Nos services informatiques</h2>
      <div className="Etapes">
        {services.map(({ node: service }) => {
          return (
            <div className="box" key={service.id}>
              <img
                src={service.picture.url}
                style={{ width: "100px", height: "100px" }}
                alt=""
              />
              <h4>{service.title}</h4>
              <p>{service.description}</p>
            </div>
          )
        })}
      </div>
    </ServicesWrapper>
  )
}

const ServicesWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 3% 0 10% 0;
  h2 {
    text-align: center;
    margin: 2% 0 0 0;
  }

  h5 {
    text-align: center;
    margin: 2% 0 0 0;
    color: #57d971;
  }

  .Etapes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 10px;
    width: 100%;
    margin: 3% 0 0 0;

    .box {
      background: #f7f9f9;
      width: 16rem;
      padding: 2%;
      margin: 1%;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
        rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
        rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
      border-radius: 0 0 0 30%;
      text-align: center;
    }
  }
`
export default Services
