import React, { createContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

export const ServicesContext = createContext()

export const ServicesProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsService(sort: { fields: id }) {
        edges {
          node {
            id
            title
            description
            picture {
              url
            }
          }
        }
      }
    }
  `)

  return (
    <ServicesContext.Provider value={[data]}>
      {children}
    </ServicesContext.Provider>
  )
}
