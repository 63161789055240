import * as React from "react"

import Home from "../components/Home"
import { EtapesProvider } from "../context/EtapesContext"
import { ServicesProvider } from "../context/ServicesContext"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Services from "../components/Services"
import Etapes from "../components/Etapes"
import About from "../components/About"

const IndexPage = () => (
  <Layout>
    <Seo title="MV Dépannage Informatique le Pradet, Dépannage informatique, montage PC Gamers" />
    <Home />
    <About />
    <EtapesProvider>
      <Etapes />
    </EtapesProvider>
    <ServicesProvider>
      <Services />
    </ServicesProvider>
  </Layout>
)

export default IndexPage
