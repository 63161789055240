import React from "react"
import styled from "styled-components"
import RDV from "../images/Analytics-rafiki.svg"
import Formulaire from "../images/Operating system-rafiki.svg"
import SAV from "../images/Mobile wireframe-rafiki.svg"
import Wave from "../images/waves.svg"

function About() {
  return (
    <AboutWrapper>
      <img style={{}} src={Wave} className="Waves" alt="" />
      <div className="Virus">
        <div className="title">
          <img src={RDV} alt="" />
          <div className="text">
            <h3>Rendez-vous rapide, dépannage sur place </h3>
            <p>
              Réservez une intervention rapidement et facilement. Nous donnons
              un rendez-vous rapidement celon vos disponibilitées
            </p>
            <p>
              Avec les informations que nous avons recueillis lors de votre
              appel, nous prévoyons le matériel et le temps nécessaire pour vous
              dépanner sur place. C'est pour cette raison, que nous prennons le
              temps neccessaire lors du contact téléphonique.
            </p>
          </div>
        </div>
        <div className="title">
          <img src={Formulaire} alt="" className="mobile" />
          <div className="text">
            <h3>Interventions de qualité et assurées</h3>
            <p>
              Vous trouverez sur nos pages dédiées au dépannage informatique à
              domicile et en entreprise, le détail de nos divers modes
              d’intervention, le matériel utilisé, et nos conseils pour bien
              préparer l’arrivée de l’informaticien. Nos autres services
              informatiques sont aux aussi présentés en détail.
            </p>
            <p>
              Avec les informations que nous avons recueillis lors de votre
              appel, nous prévoyons le matériel et le temps nécessaire pour vous
              dépanner sur place. C'est pour cette raison, que nous prennons le
              temps neccessaire lors du contact téléphonique.
            </p>
          </div>
          <img src={Formulaire} alt="" className="desktop" />
        </div>
        <div className="title">
          <img src={SAV} alt="" />
          <div className="text">
            <h3>Un SAV et une vraie relation client</h3>
            <p>
              Il est souvent compliqué pour les clients de penser à tout nous
              demander le jour J, c’est pourquoi nous reprenons gratuitement
              contact à distance avec environ 10% de nos clients dans les 48
              heures suivant l’intervention: réinstaller une imprimante,
              connecter un compte, retrouver un fichier déplacé, poser une
              question technique, etc.
            </p>
            <p>
              Nous fournissons ainsi à chaque particulier un suivi de
              l'intervention réalisé, et l’assurance d’une aide à distance
              gratuite si nécessaire.
            </p>
          </div>
        </div>
      </div>
    </AboutWrapper>
  )
}
const AboutWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  height: auto;
  margin: -1rem 0 2% 0;

  .Waves {
    margin: 1.2% 0;
    z-index: 3;
  }
  .Virus {
    width: 90%;
    height: auto;
    margin: 0 auto;
    padding: 0 0 5% 0;

    .title {
      display: grid;
      overflow: hidden;
      grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
      justify-items: center;
      justify-content: space-around;
      h3 {
        margin: 14% 0 2% 0;
        line-height: 2.3rem;
        font-size: 1.4rem;
      }
      img {
        width: 22rem;
        height: auto;
      }
      .text {
        padding: 0 10%;
        margin: 0 0 0 -10%;
      }

      @media screen and (min-width: 1000px) {
        .mobile {
          display: none;
        }
      }
    }
    @media screen and (max-width: 999px) {
      .mobile {
        display: initial;
      }
      .desktop {
        display: none;
      }
    }
    @media screen and (max-width: 768px) {
      .title {
        h3 {
          margin: 0% 0 2% 0;
          line-height: 1.5rem;
          font-size: 0.75rem;
        }
        h4 {
          margin: 5% 0 5% 0;
          font-size: 0.75rem;
        }
        p {
          font-size: 0.75rem;
        }
        img {
          width: 10rem;
          height: auto;
          padding: 4%;
        }
        .text {
          padding: 0 20%;
          margin: 0;
        }
      }
    }
  }
`

export default About
